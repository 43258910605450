<template>
  <a-modal v-model:visible="isShow" :title="`${theme}`" style="width: 100%; min-height: 100vh;" :footer="null" wrap-class-name="full-modal" :mask="false" :afterClose="modalClose">
    <md-editor
            :modelValue="content"
            :toolbars="toolbars"
            :showCodeRowNumber="true"
            :readOnly="true"
            theme="light"
            style="min-height: 100%"
            editorId="blog-details"

    />
  </a-modal>
</template>

<script>
import MdEditor from 'md-editor-v3';
import 'md-editor-v3/lib/style.css';

import * as BlogApi from '@/api/BlogApi.js';
import {message} from "ant-design-vue";


export default {
  name: 'BlogDetails',
  components: {
    MdEditor
  },
  data() {
    return {
      theme: '',
      content: '',

      toolbars: ['=', 'catalog'],

      isShow: false,


    }
  },

  methods: {
    modalClose() {
      this.content = '';
    },

    blogDetails(id, theme) {
      this.theme = theme;
      BlogApi['blogDetailsApi'](id)
      .then((result) => {
        let code = result.data.code;
        let msg = result.data.msg;
        if (code === 1) {
          this.content = result.data.data['content'];
          this.isShow = true;
        } else {
          message.error(msg);
        }

      })
      .catch((e) => {
        message.error(e.message);
      })

    },
  },

  created() {
  },

  mounted() {

  }
}
</script>


<style>
.full-modal {
  .ant-modal {
    max-width: 100%;
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh);
  }
  .ant-modal-body {
    flex: 1;
  }
}

/*隐藏原文档*/
#blog-details .md-editor-content .md-editor-input-wrapper {
  display: none;
}
/*隐藏同步滚动*/
#blog-details .md-editor-footer-right {
  display: none;
}

/*设置文档宽*/
#blog-details .md-editor-content {
  width: 100%;
  padding-left: 20%;
  padding-right: 20%;
}

/*设置目录宽*/
#blog-details .md-editor-catalog-editor {
  width: 20%;
}

</style>
