import $axios from 'axios';

export const blogCreateApi = (params) => {
    const token = sessionStorage.getItem('token');
    const companyId = sessionStorage.getItem('companyId');
    const roleId = sessionStorage.getItem('roleId');
    return $axios({
        method: 'POST',
        url: "/blog/create",
        data: params,
        headers: {
            Authorization: token,
            CompanyId: companyId,
            RoleId: roleId
        }
    })
}

export const blogPagingApi = (params) => {
    const token = sessionStorage.getItem('token');
    const companyId = sessionStorage.getItem('companyId');
    const roleId = sessionStorage.getItem('roleId');
    return $axios({
        method: 'GET',
        url: "/blog/paging",
        params,
        headers: {
            Authorization: token,
            CompanyId: companyId,
            RoleId: roleId
        }
    })
}

export const blogDetailsApi = (blogId) => {
    const token = sessionStorage.getItem('token');
    const companyId = sessionStorage.getItem('companyId');
    const roleId = sessionStorage.getItem('roleId');
    return $axios({
        method: 'GET',
        url: `/blog/details/${blogId}`,
        headers: {
            Authorization: token,
            CompanyId: companyId,
            RoleId: roleId
        }
    })
}

export const blogUpdateApi = (params) => {
    const token = sessionStorage.getItem('token');
    const companyId = sessionStorage.getItem('companyId');
    const roleId = sessionStorage.getItem('roleId');
    return $axios({
        method: 'PUT',
        url: "/blog/update",
        data: params,
        headers: {
            Authorization: token,
            CompanyId: companyId,
            RoleId: roleId
        }
    })
}

export const blogDeleteApi = (params) => {
    const token = sessionStorage.getItem('token');
    const companyId = sessionStorage.getItem('companyId');
    const roleId = sessionStorage.getItem('roleId');
    return $axios({
        method: 'DELETE',
        url: "/blog/delete",
        data: params,
        headers: {
            Authorization: token,
            CompanyId: companyId,
            RoleId: roleId
        }
    })
}
